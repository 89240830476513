@import '@/styles/_shared.scss';

:global(.checkout__main) {
  .account-form {
    :global(.input) {
      @include breakpoint-down('xs') {
        font-size: 14px;
        height: 48px;
      }
    }
  }
}

.account-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  @include breakpoint-up('sm') {
    max-width: 540px;
  }
  &.account-form--checkout {
    max-width: none;
    align-items: flex-start;
    form {
      margin: 0;
      padding: 0;
      @include breakpoint-up('sm') {
        margin: 0;
        padding: 0;
      }
    }
    .account-form-btn-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      @include breakpoint-down('xs') {
        align-items: center;
      }
      button {
        margin: 0 0 10px;
        @include breakpoint-up('sm') {
          margin: 0;
        }
      }
    }
  }
  h4 {
    margin-bottom: 10px;
    @include breakpoint-up('sm') {
      margin-bottom: 30px;
    }
  }
  form {
    text-align: center;
    margin: 20px 0 30px;
    position: relative;
    width: 100%;
    @include breakpoint-up('sm') {
      margin: 50px 0;
      padding: 0 24px;
    }
    button {
      margin-top: 30px;
      @include breakpoint-up('sm') {
        margin-top: 48px;
      }
    }
  }
  p {
    margin-bottom: 12px;
    @include breakpoint-up('sm') {
      margin-bottom: 24px;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.account-form__error,
.account-form__success {
  font-size: 12px;
  line-height: 1.1;
  color: $error;
  @include breakpoint-up('sm') {
    font-size: 14px;
  }
}

.account-form__success {
  color: $success;
}

.input-password {
  position: absolute;
}

.password-group {
	display: flex;
	position: relative;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.account-form .account-form-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 10px;

  @include breakpoint-down('xs') {
    flex-direction: column !important;
  }
}

.account-form-btn-wrapper {
  button {
    border-radius: 12px;
    height: 60px;

    @include breakpoint-down('xs') {
      height: 48px;
      margin-bottom: 0px;
    }
  }
}

.account-form .account-form-input-wrapper .input-group {
  width: 50% !important;
}

/* Estilos para el botón de Google */
.social-login-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.google-login {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 10px 16px;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  
  &:hover {
    background-color: #f8f9fa;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    background-color: #f1f3f4;
  }
}

.google-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}